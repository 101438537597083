






import { Component } from 'vue-property-decorator'
import { AsyncComponent } from 'vue'

import { Inject, logger } from '../../../support'

import { AbstractModuleUi } from '../../abstract/ui'

import { NewsletterFormState, NewsletterModule } from '../Newsletter.contracts'
import {
  NEWSLETTER_MODULE_CONFIG_MAP,
  NEWSLETTER_MODULE_KEY,
  NewsletterModuleVersion,
  newsletterModuleVersionsRegistry
} from '../Newsletter.config'
import { AnyObject, EventbusType, IEventbus } from '@movecloser/front-core'
import { INewsletterService, NewsletterServiceType } from '../../../front/shared/services/newsletter'
import { FormOptions } from '../../../front/shared/services/selenaForms'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<NewsletterModuleUi>({
  name: 'NewsletterModuleUi',
  created () {
    this.config = this.getComponentConfig(
      NEWSLETTER_MODULE_KEY,
      { ...NEWSLETTER_MODULE_CONFIG_MAP }
    )
    this.describeConsents()
    this.occupationOptions = this.newsletterService.getOccupations()
  }
})
export class NewsletterModuleUi extends AbstractModuleUi<NewsletterModule> {
  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(NewsletterServiceType)
  protected readonly newsletterService!: INewsletterService

  /**
   * Form's payload.
   */
  public formData: AnyObject = {
    email: '',
    firstName: '',
    lastName: '',
    occupation: ''
  }

  /**
   * Object containing described consents keys
   */
  public describedConsents: AnyObject[] = []

  /**
   * Determines loading state.
   */
  public isLoading: boolean = false

  /**
   * Determines message of the form.
   */
  public message: string = ''

  /**
   * Determines the state of the form.
   */
  public state: NewsletterFormState | null = null

  /**
   * ee
   */
  public errors: Array<string> = []

  public occupationOptions: FormOptions = {}

  /**
   * Determines component version.
   */
  public get component (): AsyncComponent | undefined {
    const component = newsletterModuleVersionsRegistry[this.version]

    if (typeof component === 'undefined') {
      logger(
        `NewsletterModule.ui.component(): There's no Vue component associated with the [${this.data.version}] FooterModuleVersion!`,
        'error'
      )
      return
    }

    return component
  }

  public get consents (): AnyObject[] {
    return this.getConfigProperty('consents')
  }

  public get consentsDescription (): Record<string, string> | null {
    return this.getConfigProperty('consentsDescription')
  }

  /**
   * Set default version of Newsletter
   */
  public get version () {
    const version: string = this.getConfigProperty<string>('layout')

    if (!version) {
      return NewsletterModuleVersion.Default
    }

    return version
  }

  public get isSubscriptionByUser (): boolean {
    return this.getConfigProperty<boolean>('isSubscriptionByUser')
  }

  public get useExtendedConsents (): boolean {
    return this.getConfigProperty('useExtendedConsents')
  }

  /**
   * Theme of alert.
   */
  public get theme (): string {
    return (this.state === NewsletterFormState.Error || !this.state) ? 'danger' : 'success'
  }

  /**
   * Clears current state.
   */
  public clearState (): void {
    this.state = null
  }

  protected describeConsents (): void {
    if (!this.consentsDescription) {
      return
    }

    for (const consent of this.consents) {
      const candidate = consent.option

      if (this.consentsDescription[candidate]) {
        this.formData = {
          ...this.formData,
          [this.consentsDescription[candidate]]: false
        }

        this.describedConsents.push({
          ...consent,
          option: this.consentsDescription[candidate]
        })
      }
    }
  }

  /**
   * Subscribes to newsletter
   */
  public subscribe () {
    switch (this.version) {
      case NewsletterModuleVersion.Extended:
        this.handleExtendedSubscription()
        return
      default:
        this.handleSimpleSubscription()
    }
  }

  /**
   * Check if all required keys are provided with somehow truthy value.
   * @private
   */
  public get hasAllRequiredFields (): boolean {
    const hasAllRequired = []
    for (const [consentKey, consentValue] of Object.entries(this.formData)) {
      const currentConsent = this.describedConsents.find((consent) => consent.option === consentKey)

      if (!!currentConsent && this.formData[currentConsent.option]) {
        hasAllRequired.push(currentConsent.option)
      }
    }

    return hasAllRequired.length === this.describedConsents.length
  }

  /**
   *
   * @private
   */
  private async handleExtendedSubscription (): Promise<void> {
    try {
      this.isLoading = true

      if (!this.hasAllRequiredFields) {
        //
        this.state = NewsletterFormState.Error
        return
      }

      if (this.isSubscriptionByUser) {
        if (this.useExtendedConsents) {
          this.eventBus.emit('app:newsletter.consents', {
            email: this.formData.email,
            firstName: this.formData.firstName,
            lastName: this.formData.lastName,
            occupation: this.formData.occupation,
            directMarketing: this.formData.directMarketing,
            emailOffers: this.formData.emailOffers,
            generalFormConsent: this.formData.generalFormConsent,
            partnersOffers: this.formData.partnersOffers,
            smsOffers: this.formData.smsOffers,
          })
        } else {
          this.eventBus.emit('app:newsletter.subscribe', this.formData)
        }
      } else {
        await this.newsletterService.subscribeForm(this.formData)
      }

      this.state = NewsletterFormState.Success
      this.message = this.$t('modules.Newsletter.form.state.success').toString()
    } catch (e) {
      this.state = NewsletterFormState.Error
      this.message = this.$t((e as Error).message).toString()
    } finally {
      this.isLoading = false
    }
  }

  /**
   *
   * @private
   */
  private async handleSimpleSubscription (): Promise<void> {
    try {
      this.isLoading = true

      const privacy = document.querySelector<HTMLInputElement>('#privacy-policy')

      if (privacy!.checked) {
        if (this.isSubscriptionByUser) {
          this.eventBus.emit('app:newsletter.subscribe', this.formData)
        } else {
          await this.newsletterService.subscribe(this.formData.email)
        }

        this.state = NewsletterFormState.Success
        this.message = this.$t('modules.Newsletter.form.state.success').toString()
      } else {
        this.state = NewsletterFormState.Error
        this.message = this.$t('modules.Newsletter.form.state.checkCheckbox').toString()
        this.errors.push('email')
      }
    } catch (e) {
      this.state = NewsletterFormState.Error
      this.message = this.$t((e as Error).message).toString()
    } finally {
      this.isLoading = false
    }
  }
}

export default NewsletterModuleUi
